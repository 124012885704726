@use "themes" as *;

@use "@nebular/theme/styles/globals" as *;

@include nb-install() {
  @include nb-theme-global();
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
@import "~ng-pick-datetime-ex/assets/style/picker.min.css";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ramsys-primary: mat-palette($mat-indigo);
$ramsys-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ramsys-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$ramsys-theme: mat-light-theme($ramsys-primary, $ramsys-accent, $ramsys-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($ramsys-theme);

/* You can add global styles to this file, and also import other style files */

@import "app.theme.scss";

html,
body {
  height: 100%;
  overflow: hidden;
}

* {
  font-family: "Titillium Web", sans-serif;
}

body {
  margin: 0;
  background-color: var(--main_background) !important;
  color: var(--text_color) !important;
  font-size: calc_vw(25);
}

.main-body-container {
  height: calc(100% - #{calc_vh(143)});
}

.custom-dialog {
  mat-dialog-container {
    padding: 0;
  }
}

.custom-snack-bar {
  background-color: #fff;
  color: #000;
}

.custom-snack-bar .mat-simple-snackbar {
  color: #000;
}

.custom-snack-bar .mat-simple-snackbar-action .mat-button {
  color: #fff;
  background-color: var(--button-color);
}

.expansion-box {
  background-color: var(--boxes_background_color);

  .header {
    padding: calc_vh(5) 0 calc_vh(5) calc_vw(10);
    height: calc_vh(48) !important;
    background-color: var(--boxes_header_background_color) !important;

    .box-title {
      margin-left: calc_vw(5);
      color: var(--text_color) !important;
      display: flex;
      align-content: center;
      width: 100%;
      font-size: calc_vw(28);

      .box-title-icon {
        margin: 0 calc_vw(10);
      }
    }
  }

  table {
    width: 100%;
  }

  .table-container {
    height: calc_vh(321);
    overflow: auto;

    table {
      background: var(--boxes_background_color);

      td {
        font-size: calc_vw(24);
        color: var(--text_color) !important;
      }

      th {
        height: calc_vh(56);
        font-size: calc_vw(26);
        color: var(--table_header_color) !important;
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-row {
    height: calc_vh(48);
    font-size: calc_vw(23);
    margin: calc_vh(4) calc_vw(4);
  }

  .mat-row:nth-child(even) {
    background-color: var(--boxes_background_color);
  }

  .mat-row:nth-child(odd) {
    background-color: var(--main_background);
  }

  .mat-expansion-panel-content {
    margin: 0 calc_vw(10);
  }

  .mat-expansion-indicator {
    margin-right: calc_vw(5);
  }
}

.panel-body::-webkit-scrollbar {
  width: calc_vw(16);
}

::ng-deep .mat-form-field-underline {
  display: none !important;
}

.panel-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 calc_vw(6) var(--scroll-bar-track-shadow);
  box-shadow: inset 0 0 calc_vw(6) var(--scroll-bar-track-shadow);
}

.panel-body::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-background-color);
  outline: calc_vw(1) solid var(--scroll-bar-outline);
}

::-webkit-scrollbar {
  width: calc_vw(12);
  height: calc_vh(12);
  cursor: none !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 calc_vw(10) var(--scroll-bar-track-shadow);
  border-radius: calc_vw(10);
}

::-webkit-scrollbar-thumb {
  border-radius: calc_vw(10);
  background: darkgrey;
  box-shadow: inset 0 0 calc_vw(6) var(--scroll-bar-thumb-shadow);
}

.box-content-no-data {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--text_color) !important;
  font-size: calc_vw(23);
}

.fa-close {
  cursor: pointer;
}

.error-message {
  color: var(--error-message-color);
}

.btn-info {
  border-color: var(--button-color) !important;
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: none !important;
}

.has-series-selction {
  .highcharts-point {
    opacity: 0.2 !important;
  }

  .highcharts-point-select {
    opacity: 1 !important;
  }

  .highcharts-legend .highcharts-point {
    opacity: 1 !important;
  }
}

.highchart-title-tooltip {
  opacity: 0;
  position: absolute;
  transition: opacity 0.5s ease-out;
  background-color: var(--hover-color);
  border-radius: 60px;
  font-size: 24px;
  padding: 10px;

  &.title-tooltip-active {
    opacity: 0.73;
  }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.loadmask-icon {
  position: absolute;
  top: 40%;
  z-index: 99999;
  left: 45%;
}

.report-paginator {
  ::ng-deep .mat-paginator-outer-container {
    background-color: var(--boxes_background_color) !important;
    height: calc_vh(56);
    font-size: calc_vw(23);
    color: var(--text_color);

    .mat-icon-button {
      color: var(--text_color) !important;
    }

    .mat-select-value,
    .mat-select-arrow {
      font-size: calc_vw(23);
      color: var(--text_color) !important;
    }

    .mat-select {
      background-color: var(--main_background);
      width: calc_vw(75);
      position: relative;
      top: calc_vh(3);
    }

    .mat-form-field-infix {
      padding: 0;
    }

    .mat-select-trigger {
      padding: calc_vh(5) 0;
    }

    .mat-paginator-icon {
      width: calc_vw(28) !important;
    }

    .mat-paginator-container {
      flex-wrap: unset !important;
      min-height: 0 !important;
    }

    .mat-paginator-range-label {
      margin: 0 calc_vw(40) 0 calc_vw(40) !important;
    }

    .mat-paginator-page-size,
    .mat-form-field-flex {
      height: calc_vh(40) !important;
      align-items: center !important;
    }
  }
}

::ng-deep .mat-tooltip {
  margin-top: -5px !important;
}

::ng-deep .custom-tooltip {
  margin-top: 0 !important;
}

//Newly Added for RHS sections

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.paragraph-text,
.title {
  margin: 0 !important;
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700 !important;
}

.radius-15 {
  border-radius: 15px;
}

.box-border {
  border: 1px solid var(--boxes-border);
}

@for $gap from 1 through 15 {
  .gap-#{$gap}x {
    gap: $gap + px;
  }
}

.card-wrap {
  gap: 15px;

  .gi-row {
    .gi-text {
      .title {
        font-size: calc_vw(32);
        @media screen and (max-width: 1800px) {
          font-size: 15px;
        }
      }
    }
    .chart-value {
      border: 1px solid var(--boxes-border);
      .item-value {
        padding: 0.5rem 1rem;
        @media screen and (min-height: 1300px) {
          padding: 0rem 3rem;
        }
        align-items: center;
        &:not(:last-child) {
          border-right: 1px solid var(--boxes-border);
        }
        @media screen and (max-width: 1800px) {
          padding: 5px 5px;
        }
      }
    }
  }
}

.chart-value {
  .item-value {
    span {
      color: var(--chart-orange);
      font-size: 13px;
      font-weight: 600;
    }
    p {
      color: #eaeaea;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.chart-block {
  padding: 0.5rem;
  @media screen and (max-width: 1800px) {
    padding: 8px 8px 8px 8px;
  }
  @media screen and (max-height: 900px) {
    padding: 8px 8px 8px 8px;
  }
  @media screen and (min-height: 1300px) {
    padding: 1rem;
  }
}

.chartone-block {
  height: 100%;
  .chart-wrap {
    .chart {
      flex: 0 0 100%;
    }
    .chart-value {
      gap: 10px;
      flex-direction: column;
    }
  }
}

.charttwo-block {
  .chart-wrap {
    gap: 20px;
    @media screen and (max-width: 1800px) {
      gap: 5px;
    }
    @media screen and (min-height: 1300px) {
      gap: 67px;
    }
    .chart-value {
      gap: 10px;
      justify-content: space-between;
      padding: 0 20px;
      .item-value {
        gap: 5px;
        &:not(:last-child)::after {
          content: "";
          width: 1px;
          height: 100%;
          background: linear-gradient(
            to bottom,
            #20202c 0%,
            #2d2c3a 50%,
            #20202c 100%
          );
          margin-left: 10px;
        }
      }
    }
  }
}

.block-head {
  gap: 10px;
  margin-bottom: 1rem;
  @media screen and (max-height: 950px) {
    margin-bottom: 0.5rem;
  }
  .title {
    font-size: 16px;
  }
}

.custom-popover {
  .popover-header,
  .popover-body {
    padding: 0.5rem 0.75rem !important;
    background-color: #1b1b25;
    border: 1px solid #afafaf60;
    color: #fff;
  }
  .arrow::after,
  .arrow::before {
    border-left-color: #1b1b25 !important;
    border-right-color: #1b1b25 !important;
  }
}
.p-10 {
  padding: 0.625rem;
}
.px-10 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.py-10 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.p-left-10 {
  padding-left: 0.625rem;
}
.p-right-10 {
  padding-right: 0.625rem;
}
