@use "sass:math";

:root {
  --main_background: #101012;
  --text_color: white;
  --white-color-1: #fff;
  --black_text_color: #000;
  --boxes_background_color: #1b1b25;
  --table_header_color: #7d8a91;
  --boxes_header_background_color: #272634;
  --boxes_header_background_color_light: #7d8a91;
  --boxes_header_background_color_medium: #4c6475;
  --error_color: #ff0000;
  --blue_color: #0d57a0;
  --gray_color: #5e666b;
  --dark-gray: #717171;
  --gray_color_one: #ccc;
  --button-color: #37374f;
  --button-hover-color: #7d8a91;
  --border-color: lightgray;
  --alarms-border-color: gray;
  --alarms-list-hover-color: #254463;
  --led-red: red;
  --led-black: black;
  --led-green: green;
  --led-blue: blue;
  --active-link: #45acdd;
  --error-message-color: #f44336;
  --default-font-size: 23px;
  --placehoder-color: lightgray;
  --scroll-bar-background-color: darkgrey;
  --scroll-bar-outline: slategrey;
  --scroll-bar-track-shadow: rgb(120, 121, 118);
  --scroll-bar-thumb-shadow: rgba(0, 0, 0, 0.5);
  --zoom-btn-shadow-first: rgba(0, 0, 0, 0.2);
  --zoom-btn-shadow-second: rgba(0, 0, 0, 0.14);
  --zoom-btn-shadow-third: rgba(0, 0, 0, 0.12);

  --chart-red: #ff3e00;
  --chart-green: #6fda44;
  --chart-orange: #fac500;
  --chart-blue: #00b2ff;
  --chart-black: black;
  --filter-selection: #243046;

  --red-1: #ec8773;
  --red-2: #fabb74;
  --green-1: #83d487;
  --blue-1: #38b3ef;
  --blue-2: #6a6cd1;
  --blue-3: #2589a0;
  --action-blue: #3879a3;
  --hover-color: #2b3a54;
  --selected-filter-color: #243046;
  --filter-btn-color: #37374f;
  --selection-color: #37374f;
  --pink: #ec8773;
  --tile-black: #505050;
  --tile-brown: #ebb473;
  --purple-1: #6b6cd1;
  --blue-4: #25899f;
  --open-tile: #ec8772;
  --fault-tile: #fabc74;
  --resolved-tile: #84d486;
  --escalated-tile: #38b3ef;
  --underloaded-tile: #a5d16b;
  --idle-tile: #74c6fa;
  --not-at-desk-tile: #ecb472;
  --up-tile: #4699cd;
  --chart-selection-color: #f49234;
  --table-header: #fde89a;
  --boxes-border: #57524c;
}

$boxes_header_font_size: calc_vh(28);
$boxes_header_font_size_sm: calc_vh(24);
$table_header_font_size: calc_vh(25);

$default-font-size: calc_vh(23);
$default-font-size-sm: calc_vh(15);
$boxes_header_height: calc_vh(48);

@function calc_vh($args) {
  @return math.div($args * 100, 1440) * 1vh;
}

@function calc_vw($args) {
  @return math.div($args * 100, 3440) * 1vw;
}
